<template>
  <div class="c-log-list">
    <div class="c-log-list__wrapper">
      <div class="c-log-list__body">
        <div class="c-log-list__item" v-for="(errors, errorKey) in errorPayload" :key="errorKey">
          <!-- Shop based errors -->
          <div class="mt-4" v-for="(shopErrors, shopKey) in errors.messages" :key="shopKey">
            <p><strong><i class="fas fa-exclamation-triangle"></i> SHOP ERROR :</strong></p>
              <p class="mt-2">{{ '• ' + shopErrors }}
              </p>
            </div>
            <!-- Product based errors -->
            <div class="mt-4" v-for="(productErrors, productKey) in errors.products" :key="productKey">
              <p><strong><i class="fas fa-exclamation-triangle"></i>PRODUCT ERROR : {{productKey}}</strong></p>
              <p class="mt-2" v-for="message in productErrors.messages" :key="message">{{ '• ' + message }}</p>
              <!-- Sku based errors -->
              <div class="mt-4" v-for="(skuErrors, skuKey) in productErrors.skus" :key="skuKey">
                <p><strong><i class="fas fa-exclamation-triangle"></i>
                  SKU ERROR: {{skuKey}}</strong></p>
                <p class="mt-2" v-for="message in skuErrors.messages" :key="message">{{ '• ' + message }}</p>
              </div>
            </div>
          </div>
          <!-- General or request errors -->
          <div class="c-log-list__item" v-for="(generalError, generalErrorKey) in generalErrors" :key="generalErrorKey">
            <p><strong><i class="fas fa-exclamation-triangle"></i>ERROR: {{generalErrorKey}}</strong></p>
            <p class="mt-2">{{ '• ' + generalError }}</p>
              <hr>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    name: 'ErrorLogList',
    props: {
        errors: {
            default: null,
        },
    },

    computed: {
        generalErrors () {
            const generalErrors = this.errors.errorMessage
            delete generalErrors.shops
            return [...new Set(generalErrors.messages)]
        },
        errorPayload () {
            if (!this.errors ||
                !this.errors.errorMessage ||
                !this.errors.errorMessage.shops
            ) {
                return null
            }
            return Object.values(this.errors.errorMessage.shops)
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-log-list {
        border: 1px solid #d6d6d6;
        height: 320px;
        position: relative;
        margin: auto;

        &__wrapper {
            overflow-y: scroll;
            height: 100%;
            overflow-wrap: break-word;
            padding-right: 25px;
        }

        &__header {
            color: #666666;
            padding: 10px 15px;
            position: absolute;
            margin: 0;
            background-color: white;
            width: 100%;
            z-index: 2;
            top: 0;
            border-bottom: 1px solid #d6d6d6;
        }

        &__item {
            font-size: 12px;
            border-bottom: 1px solid rgba(151,151,151, .2);
            padding: 10px 0 10px 40px;

            p {
                margin: 0;
                line-height: 20px;
                position: relative;
            }

            &:nth-last-child(1) {
                border: none;
            }

            i {
                font-size: 18px !important;
                position: absolute;
                left: -25px;
                margin-top: -2px;
            }
        }
    }
</style>
