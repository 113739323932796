<template>
    <div>
        <v-title title="Product Feed Overview"></v-title>

        <title-box>
            <div class="row">
                <div class="col col-9">
                    <h1>Product Feed Details</h1>
                </div>

                <div class="col col-3 text-right">
                    <mercur-button
                        class="btn btn-raised btn-primary"
                        :to="{ name: 'EditProductFeed', params: { productFeedId: this.$route.params.productFeedId }}">
                        Edit
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('listProductFeed')">
                <div class="mt-4">
                    <pagination :pagination="pagination" @change="changeAmountOfItems"></pagination>
                    <merchant-data-table
                        :options="options"
                        :url="url"
                        ref="table"
                        @paginationSet="updatePagination"
                        :isNotApplicationBound="true">
                    </merchant-data-table>
                </div>
            </template>
            <span v-else>Not allowed to see this view</span>
        </div>
        <!-- Error Details Dialog -->
        <mercur-dialog
            :is-open.sync="isDialogOpen">
            <template #header>
                <v-title title="Error Details"></v-title>
                <h2>Error Details</h2>
            </template>
            <mercur-card>
                <error-log-list
                    :errors="dialogData">
                </error-log-list>
            </mercur-card>
            <template #footer>
                <mercur-button class="btn btn-primary" @click="isDialogOpen = false">Close</mercur-button>
            </template>
        </mercur-dialog>
    </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import ErrorLogList from '@/components/elements/product-feed/ErrorLogList'

export default {
    name: 'ProductFeedDetails',
    components: { MerchantDataTable, Pagination, ErrorLogList },

    data () {
        return {
            url: CONFIG.API.ROUTES.PRODUCT_FEEDS.DETAILS.replace('{productFeedId}', this.$route.params.productFeedId),
            isDialogOpen: false,
            activeData: null,
            pagination: {},
            amountOfDisplayedItems: 50,
            isLoading: false,
            dialogData: {},
            options: {
                columns: {
                    'Configuration Name': { field: 'configurationName', filter: false },
                    'generation Status': { field: 'generationStatus', filter: false },
                    'country Codes': { field: 'countryCodes', filter: false },
                    'language': { field: 'language', filter: false },
                    'Date Updated': { field: 'dateUpdated', filter: false, sortable: true },
                },
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Download File',
                            onClick: ({ data }) => {
                                this.downloadFile(data)
                            },
                            isDisabled: ({ data }) => {
                                return (!this.hasPermission('downloadProductFeedFile') || !(data && data.fileName))
                            },
                        },
                        {
                            text: 'Error Details',
                            isDisabled: ({ data }) => {
                                return data.generationStatus === 'DONE'
                            },
                            onClick: ({ data }) => {
                                this.openErrorDialog(data)
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: [] },
                sortModel: [
                    {
                        colId: 'dateUpdated',
                        sort: 'desc',
                    },
                ],
            },
        }
    },
    methods: {
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },

        openErrorDialog (data) {
            this.dialogData = data
            this.isDialogOpen = true
        },

        downloadFile (data) {
            const url = CONFIG.API.ROUTES.PRODUCT_FEEDS.GET_DOWNLOAD_URL

            const payload = {
                fileName: data.fileName,
            }

            this.addJob(url)
            this.$root.$emit('notification:global', {
                message: 'Downloading file...',
            })
            this.post(url, payload).then(data => {
                window.open(data.url, '_blank')
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
            })
        },

    },
}
</script>

<style lang="scss" scoped>
.error-dialog {
    width: 90%;
}

.error-card {
    width: 72vw;
}
</style>
